* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}
/* $red = #e31c25; */
html,
.App {
  background: #111111;
  color: rgb(177, 168, 168);
}

.navbar {
  background-color: #111111;
}
.home-container {
  text-align: center;
  padding: 75px;
  color: white;
  width: 100%;
  height: 1000px;
  background-image: url("assets/background-pic.jpg");
  background-position: center;
  background-size: cover;
}

.text-container {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.home-container p {
  font-size: 50px;
}

h1,
p {
  font-family: "Rubik", sans-serif;
  text-align: center;
  color: rgb(177, 168, 168);
}
p {
  font-size: 18px;
}

.home-container a {
  margin: 10px;
  color: white;
}

#title {
  padding-top: 4rem;
  color: rgb(177, 168, 168);
  text-align: center;
  font-weight: 600;
}

.project-list {
  justify-items: center;
  width: 100%;
  height: max-content;
  padding: 2rem 1rem;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 1rem;
}
@media screen and (max-width: 1230px) {
  .project-list {
    grid-template-columns: repeat(2, 1fr);
  }
}
@media screen and (max-width: 600px) {
  .project-list {
    grid-template-columns: repeat(1, 1fr);
  }
}

.project-container {
  width: 80%;
  height: 400px;
  margin-bottom: 3rem;
}
.project {
  width: 100%;
  height: 100%;
  transition: transform 1s ease-in-out;
  transform-style: preserve-3d;
}
.project-container:hover .project {
  transform: rotateY(180deg);
}
.project div {
  width: 100%;
  height: 100%;
  position: absolute;
  backface-visibility: hidden;
}
.project-back {
  position: absolute;
  color: white;
  transform: rotateY(180deg);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.project-back .back-info {
  margin: 10px;
  justify-content: center;
  align-items: center;
}
.back-info Button {
  position: absolute;
  top: 50%;
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
}

.project-back a {
  transition: all 1s ease;
}
.project-back a:hover {
  transform: scale(1.2);
  -webkit-transform: scale(1.2);
  -ms-transform: scale(1.2);
}
.project-back p {
  padding: 10px;
}
#name {
  color: rgb(177, 168, 168);
  transition: all 0.5s ease;
}
#name:hover {
  transform: scale(1.1);
  -webkit-transform: scale(1.1);
  -ms-transform: scale(1.1);
}
.photo-image {
  position: absolute;
  bottom: 0;
  right: 0;
  cursor: pointer;
}
.tech-images {
  position: absolute;
  bottom: 0;
  left: 0;
}
.tech-images svg {
  margin-right: 10px;
}
.tech-images img {
  margin-right: 10px;
  width: 2rem;
}

.fill-white {
  filter: invert(100%) sepia(0%) saturate(2%) hue-rotate(251deg) brightness(108%) contrast(101%)
}
.buttons-flex {
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: space-around;
  justify-content: space-around;
}
.buttons-flex a {
  height: 40px;
}

.project-front {
  border-radius: 1rem;
  background-position: center;
  object-fit: cover;
  background-size: cover;
}

.about-container,
.contact-container {
  width: 100%;
  padding-right: 2rem;
  margin-left: 1rem;
  position: relative;
}
.about-text {
  width: 80%;
  margin: auto;
}
.about-container {
  height: auto;
}
.contact-container {
  height: 500px;
  margin-bottom: 50px;
}
.toast-container {
  margin: auto;
  width: 50%;
}
.toast-body {
  display: flex;
  justify-content: space-between;
}
.form-container {
  display: flex;
  justify-content: center;
}
.form-group{
  width: 350px;
}
.footer-container {
  background: #232222;
  height: 75px;
  display: flex;
  justify-content: space-around;
  align-items: center
}

.footer-container svg:hover {
  cursor: pointer
}